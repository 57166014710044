.Pagina {
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  background-color: rgb(237, 237, 237);
  min-height: 100vh;
}


.Header {
  display: flex;
  flex-wrap: wrap;  
  flex-direction: column;
}


.Titolo {
  text-align: center;
  font-family: helvetica;
  font-size: 100px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0.5em;
}


.Testo {
  text-align: justify;  
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 3em;
  font-size: 20px;
}

.Menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 25%; 
  margin-right: 25%;
  justify-content: space-evenly;
  gap: 20px; 
  padding-bottom: 10em;
}


.Button {
  color: #111111;
  background-color: white;
  flex-wrap: wrap;
  border-radius: 1em;
  border-style: none;
  font-size: 20px;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-right: 0.3em;
  padding-left: 0.3em;
  width: 30%;
  height: 4.5em;
}